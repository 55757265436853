<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Warehouse:Warehouseinfo:Create'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="save" v-right-code="'Warehouse:Warehouseinfo:Create'">保存</el-button> </div>
            </div>
        </div>

        <el-tabs class="done" type="border-card" v-model="activeName">
                <el-tab-pane label="基本信息" name="tabBasic">
                    <div class="form-list">
                        <el-form ref="_warehouseInfoForm" :model="dataSource" :rules="warehouseInfoCheckRule">
                            <el-form-item>
                                <div :hidden="!isDisabled">
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>仓库编号：</el-col>
                                    <el-col :span="7">
                                        <el-form-item prop="WarehouseNo">
                                            <el-input v-model="dataSource.WarehouseNo" :disabled="true" :maxlength="30" :minlength="4" placeholder="仓库编号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>仓库名称：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="WarehouseName">
                                        <el-input v-model="dataSource.WarehouseName" :maxlength="30" :minlength="4" placeholder="仓库名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">仓库简称：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="ShortName">
                                        <el-input v-model="dataSource.ShortName" :maxlength="30" :minlength="4" placeholder="仓库简称"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title">服务商编码：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="MemberCode">
                                        <el-input v-model="dataSource.MemberCode" :disabled="isDisabled && !dataSource.IsEditMember" :maxlength="30" :minlength="4" placeholder="服务商编码" readonly suffix-icon="el-icon-search" @click.native="memberOptions.visible = true && (!isDisabled || dataSource.IsEditMember)"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">服务商名称：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="MemberName">
                                        <el-input v-model="dataSource.MemberName" :maxlength="30" :minlength="4" :disabled="true" placeholder="服务商名称"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>仓库类别：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="MemberType">
                                        <DictionarySelect v-model="dataSource.OperationModel" dicTypeCode="WarehouseOperationModelCode" datType="Int"></DictionarySelect>
                                        <!-- <el-select v-model="dataSource.OperationModel" :maxlength="200">
                                            <el-option v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select> -->
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>仓库类型：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="OperationTypeCode">
                                        <DictionarySelect v-model="dataSource.OperationTypeCode" dicTypeCode="WarehouseOperatorTypeCode" datType="String"></DictionarySelect>
                                        <!-- <el-select v-model="dataSource.OperationTypeCode" collapse-tags placeholder="请选择" :maxlength="200">
                                            <el-option v-for="item in dicOptions"
                                                    :key="item.DictionaryCode"
                                                    :label="item.DictionaryName"
                                                    :value="item.DictionaryCode">
                                            </el-option>
                                        </el-select> -->
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title">仓库面积：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="TotalArea">
                                        <el-input v-model="dataSource.TotalArea" :maxlength="30" :minlength="4" oninput="this.value=this.value.replace(/[^0-9]+/,'');" placeholder="仓库面积"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>所在地区：</el-col>
                                <el-col :span="9">
                                    <Distpicker :placeholders="dataSource" ></Distpicker>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item prop="WarehouseAddress">
                                        <el-input v-model="dataSource.WarehouseAddress" :maxlength="30" :minlength="4" placeholder="详细地址"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="WarehouseContactName">
                                        <el-input v-model="dataSource.WarehouseContactName" :maxlength="30" :minlength="4" placeholder="联系人"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>电话：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="WarehouseContactPhone">
                                        <el-input v-model="dataSource.WarehouseContactPhone" :maxlength="30" :minlength="4" placeholder="电话"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="3" class="form-title">备注：</el-col>
                                <el-col :span="17">
                                    <el-form-item prop="Memo">
                                        <el-input type="textarea" v-model="dataSource.Memo" :maxlength="30" :minlength="4" placeholder="备注"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="物流组织" name="tabLogOrg">
                    <div class="form-padding h-handle-table h-table-list">
                        <div class="h-handle-button  h-handle-bg">
                            <el-button type="text" size="small" @click="orgControlShow(0)">新增</el-button>
                        </div>
                        <div class="table-s">
                            <el-table ref="_orgList" :data="dataSource.MemberOrgList" style="width: 100%" border highlight-current-row>
                                <el-table-column type="index" width="55" label="序号"></el-table-column>
                                <el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
                                <el-table-column prop="OrgName" label="组织机构名称"></el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <el-button @click="dataSource.MemberOrgList.splice(scope.$index, 1)">移除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
        </el-tabs>
        
        <DialogEx :options="memberOptions" title="选择服务商" @onOk="onMemberOk">
            <MemberInfo :config="memberConfig" @onSelectedRow="onMemberSelectedRow" @onDBClick="onMemberOk"></MemberInfo>
        </DialogEx>

        <DialogEx :options="orgConfig.viewOptions" title="组织机构" @onOk="onOrgControlOk">
			<OrgSelectControl ref="orgControl" :config="orgConfig.data" @onSelectedRow="onOrgControlSelectedRow">
			</OrgSelectControl>
		</DialogEx>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeName:'tabBasic',
                currentSelectedRow: {},
                multipleSelection: [],
                dicOptions: [],
                isDisabled: false,
                memberOptions: {
                    visible: false,
                    size: 'large'
                },
                memberConfig: {
                    isDetailDisplay: Object.freeze(true)
                },
                warehouseInfoCheckRule: {
                    WarehouseName: [{ required: true, message: '请输入仓库名称', trigger: 'blur' }],
                    WarehouseAddress: [{ required: true, message: '请输入仓库地址', trigger: 'blur' }],
                    WarehouseContactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                    WarehouseContactPhone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                    OperationTypeCode: [{ required: true, message: '请选择运营商类型', trigger: 'blur' }],
                },
                options: [
                    { label: '三方', value: '0' },
                    { label: '直营', value: '100' },
                    { label: '加盟', value: '200' },
                ],
                orgConfig:{
                    viewOptions: {
                        visible: false,
                        size: 'small',
                        customClass: 'controlDialog'
                    },
                    data:{
						multiple:true,
                    },
                    selectedRow:{}
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource: {}
        },
        mounted() {
            this.Event.$on("clearEditWarehouseInfoForm", () => this.resetForm);
            //this.getOptionList();
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled =  this.dataSource.WarehouseId!=null;
                },
                deep: true
            }
        },
        methods: {
            resetForm() {
                !this.isPassValidate && this.$refs['_warehouseInfoForm'].resetFields();//清空表单
            },
            add: function () {
                this.Event.$emit("onAdd");
            },
            save: function () {
                var _this = this;
                this.$refs["_warehouseInfoForm"].validate((valid) => {
                    this.isPassValidate = valid;
                    if (valid) {
                        if (this.dataSource.ProvinceID == "" || this.dataSource.CityID == "" || this.dataSource.ProvinceID == null || this.dataSource.CityID == null) {
                            this.Utils.messageBox("省/市不能为空.", "error");
                            return;
                        }
                        var routeName = this.dataSource.WarehouseId == null ? "create" : "update";
                        this.$ajax.send("omsapi/warehouse/warehouseinfo/" + routeName, "post", this.dataSource, (data) => {
                            this.$parent.syncDataSource();
                            this.Event.$emit("reloadWarehouseInfoPageList");
                            this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            onMemberSelectedRow: function (row) {
                this.currentSelectedRow = row;
            },
            onMemberOk(isDbClick) {
                if (this.currentSelectedRow.MemberId != null) {
                    this.dataSource.MemberName = this.currentSelectedRow.MemberName;
                    this.dataSource.MemberCode = this.currentSelectedRow.MemberCode;
                    this.dataSource.MemberId = this.currentSelectedRow.MemberId;
                }
                if (isDbClick) {
                    this.memberOptions.visible = false;MemberId
                }
            },
            getOptionList: function () {
                var _this = this;
                _this.$ajax.send("omsapi/sysdictionary/getsysdicchildrenbyparent", "get", { DictionaryParent: 920 }, (data) => {
                    if (data.IsSuccess) {
                        _this.dicOptions = data.Result;
                    }
                    else {
                        _this.Utils.messageBox("获取字典列表失败", "warning");
                    }
                });
            },
            orgControlShow(){
                debugger;
				this.orgConfig.viewOptions.visible=true;
			},
			onOrgControlSelectedRow(rows){
				debugger;
                if (rows && rows.length>0) {
                    this.orgConfig.selectedRow = rows;
				}
            },
            onOrgControlOk(){
				var data = this.$refs.orgControl.getSelectedAll();
				this.dataSource.MemberOrgList = this.Utils.arrayUnion([this.dataSource.MemberOrgList,data.OrgList],'OrgId');
            },
        },
        components: {
            "MemberInfo": resolve => { require(['@/components/business/selector/memberselector.vue'], resolve) },
            "OrgSelectControl": resolve => {
				require(['@/components/enterprise/components/logorgselector.vue'], resolve)
			},
        }
    }

</script>

<style>
</style>